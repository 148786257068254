import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ProductPriceBS } from "@style-variables";
import { getProductDiscount } from "@utils/functions";
import { formatCurrency, getComponentClassName } from "@utils/strings";
import { withPlaceholder } from "@components-core/Placeholder";
import EnergyClass from "../EnergyClass/EnergyClass";
import ProductPriceProps from "@prop-types/ProductPriceProps";

const ProductPrice = props => {
  const discount = getProductDiscount(props);

  const renderPrice = (as, price, currencyPrefix, currencySuffix, suffix) => {
    if (false === !!price) {
      return null;
      // return React.createElement(
      //   React.Fragment,
      //   { className: getComponentClassName(ProductPriceBS, suffix) },
      //   <>&nbsp;</>
      // );
    }

    return React.createElement(
      as,
      { className: getComponentClassName(ProductPriceBS, suffix) },
      formatCurrency(price, currencyPrefix, currencySuffix)
    );
  };

  const price = (
    <Row className={props.inline ? null : "d-flex"}>
      {renderPrice(
        "span",
        props.newPrice,
        props.currencyPrefix,
        props.currencySuffix,
        "actual"
      )}
      {props.inline ? <span className="px-1" /> : null}
      {renderPrice(
        "del",
        props.oldPrice,
        props.currencyPrefix,
        props.currencySuffix,
        "old"
      )}
    </Row>
  );

  let children = null;

  if (props.energyClass) {
    children = (
      <Row>
        <Col xs={8}>{price}</Col>
        <Col xs={4}>
          <EnergyClass
            energyClass={props.energyClass}
            onClick={props.energyClassClick}
            title={props.i18n.PRODUCT_DATASHEET}
          />
        </Col>
      </Row>
    );
  } else {
    children = price;
  }

  return (
    <Container
      className={getComponentClassName(ProductPriceBS, null, props.className)}
      data-product-newprice={props.newPrice}
      data-product-oldprice={props.oldPrice}
      data-product-currencycode={props.currencyCode}
      data-product-currencysuffix={props.currencySuffix}
      data-product-discount-value={discount.value ? discount.value : null}
      data-product-discount-percent={discount.value ? discount.percent : null}
    >
      {withPlaceholder(props.placeholder, children)}
      {withPlaceholder(props.placeholder, props.children)}
    </Container>
  );
};

ProductPrice.propTypes = ProductPriceProps;

export default ProductPrice;
