import ModalDialog from "@components-core/ModalDialog";
import Picture from "@components-core/Picture";
import PureComponent from "@components-core/PureComponent";
import { connectHOCs } from "@components-utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  addCartChangeSubscriber,
  removeCartChangeSubscriber
} from "@redux-actions/cart";
import {
  CheckoutRelatedProductsBS,
  ShoppingCartAssistantBS
} from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Button, CloseButton, Col, Container, Row } from "react-bootstrap";
import { CART_ADD_PRODUCT } from "../../redux/actionTypes";
import CheckoutRelatedProducts from "../Checkout/RelatedProducts";
import ProductPrice from "../Product/Price";

class ShoppingCartAssistant extends PureComponent {
  constructor(props) {
    super(props);

    this.myRef = React.createRef();

    this.state = {
      show: false,
      cartItem: null,
      top: null
    };

    // the observer of page-header mutations
    this.observer = null;

    this.timeout = null;

    this.onCartAddProduct = this.onCartAddProduct.bind(this);
    this.handleRedirectCheckout = this.handleRedirectCheckout.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.clearTimeout = this.clearTimeout.bind(this);
  }

  handleClose(e) {
    this.setState({ show: false });

    this.clearTimeout();
  }

  clearTimeout() {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
      this.timeout = null;
    }
  }

  handleRedirectCheckout(e) {
    const url = this.props.pathfinder.get("checkout");

    this.setState({ show: false });

    this.props.history.push(url);
  }

  onCartAddProduct({ type, product, preorder, quantity, updated }) {
    if (type !== CART_ADD_PRODUCT) {
      return;
    }

    // autohide the assistant after `timeout` seconds
    const callback = this.props.timeout
      ? () => {
          this.clearTimeout();

          this.timeout = setTimeout(
            () => this.setState({ show: false }),
            this.props.timeout * 1000
          );
        }
      : null;

    this.setState(
      { cartItem: { product, preorder, quantity }, show: true },
      callback
    );
  }

  componentDidMount() {
    // subscribe to cart-add product event
    this.props.addCartChangeSubscriber(this.onCartAddProduct);

    if (!this.props.modal) {
      this.observer = this.observePageHeaderContainer(() => {
        const rect = this.myRef.current.previousSibling.getBoundingClientRect();
        this.setState({ top: rect.height });
      });
    }
  }

  componentWillUnmount() {
    this.clearTimeout();

    // unsubscribe from cart-add product event
    this.props.removeCartChangeSubscriber(this.onCartAddProduct);

    if (this.observer) {
      this.observer.disconnect();
    }
  }

  /**
   * @description Monitor page-header mutations
   * @param {Function} callback A callback function called on a new mutation
   * @returns {MutationObserver}
   * @memberof ShoppingCartAssistant
   */
  observePageHeaderContainer(callback) {
    const config = { childList: true, subtree: true };

    const cb = function (mutationsList, observer) {
      if (
        Array.from(mutationsList).some(
          mutation => "childList" === mutation.type
        )
      )
        if ("function" === typeof callback) {
          callback();
        }
    };

    const observer = new MutationObserver(cb);
    observer.observe(this.myRef.current.previousSibling, config);

    return observer;
  }

  renderRelatedArticles() {
    return (
      <div className={"px-5 " + CheckoutRelatedProductsBS}>
        <h3 className="text-center my-1">{this.props.title}</h3>
        <CheckoutRelatedProducts
          wrapIntoCard={false}
          title={this.props.title}
          text={this.props.text}
        />
      </div>
    );
  }

  renderArticlePrice() {
    const _i18n = this.props.i18n.components.Product;

    const productItem = this.state.cartItem.product;

    return (
      <ProductPrice
        i18n={_i18n}
        oldPrice={productItem.oldPrice}
        newPrice={productItem.newPrice}
        currencyPrefix={productItem.currencyPrefix}
        currencySuffix={productItem.currencySuffix}
        currencyCode={productItem.currencyCode}
        inline={false}
        placeholder={false}
      />
    );
  }

  render() {
    const show = this.state.show ? null : "d-none";
    const _i18n = this.props.i18n.components.ShoppingCart.ASSISTANT;

    let content = null;

    if (this.state.cartItem) {
      const title = this.state.cartItem.product.title;

      const img = {
        ...this.state.cartItem.product.img,
        title: this.state.cartItem.product.title
      };

      // const topFeatures = this.state.cartItem.product.topFeatures.join("|");

      const price = this.renderArticlePrice();

      const relatedArticles = this.renderRelatedArticles();

      content = (
        <Container
          className={getComponentClassName(
            ShoppingCartAssistantBS,
            null,
            "is-modal"
          )}
        >
          <Row>
            <Col className="header text-center">
              {_i18n.feedback}
              <FontAwesomeIcon icon="check-circle" className="mx-2" />
            </Col>
          </Row>
          <Row className="my-4">
            <Col className="mx-auto" md={8}>
              <Container>
                <Row>
                  <Col
                    xs="8"
                    md="5"
                    className={getComponentClassName(
                      ShoppingCartAssistantBS,
                      "thumbnail"
                    )}
                  >
                    <Picture
                      {...img}
                      removeBackground
                      imgSize={{ maxWidth: "142px", maxHeight: "142px" }}
                    />
                  </Col>
                  <Col xs="4" md="7" className="pl-0">
                    <Container
                      className={getComponentClassName(
                        ShoppingCartAssistantBS,
                        "title-price",
                        "d-flex flex-column justify-content-around h-100"
                      )}
                    >
                      <Row>
                        <Col>{title}</Col>
                      </Row>
                      <Row>
                        <Col>{price}</Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col>{relatedArticles}</Col>
          </Row>
          <Row>
            <Col xs="6" md="6">
              <Button
                variant="success"
                className="w-100"
                onClick={this.handleRedirectCheckout}
              >
                {_i18n.checkout}
              </Button>
            </Col>
            <Col md="6" xs="6">
              <Button
                variant="secondary"
                className="w-100"
                onClick={this.handleClose}
              >
                {_i18n.shopping}
              </Button>
            </Col>
          </Row>
        </Container>
      );
    }

    const style = {
      top: this.state.top
    };

    if (this.props.modal) {
      return (
        <ModalDialog
          show={this.state.show}
          onHide={this.handleClose}
          title={null}
          body={
            <>
              <CloseButton className="float-right" onClick={this.handleClose} />
              {content}
            </>
          }
          buttons={[]}
          size="xl"
          centered
          backdrop
        />
      );
    } else if (this.props.float === "right") {
      style.right = "3rem";
    } else if (this.props.float === "left") {
      style.left = "3rem";
    }

    return (
      <div
        style={style}
        className={getComponentClassName(ShoppingCartAssistantBS, null, show)}
        ref={this.myRef}
      >
        {content}
      </div>
    );
  }
}

ShoppingCartAssistant.propTypes = {
  float: PropTypes.oneOf(["left", "right"]),
  timeout: PropTypes.number,
  modal: PropTypes.bool
};

ShoppingCartAssistant.defaultProps = {
  float: "left",
  modal: true,
  addCartChangeSubscriber
};

ShoppingCartAssistant.mapValueToProps = value => {
  const relatedProducts =
    value.i18n.components.ShoppingCart.ASSISTANT.relatedProducts || {};

  return {
    title: relatedProducts.TITLE,
    text: relatedProducts.TEXT
  };
};

ShoppingCartAssistant.mapStateToProps = null;
ShoppingCartAssistant.mapDispatchToProps = {
  addCartChangeSubscriber,
  removeCartChangeSubscriber
};

export default connectHOCs(ShoppingCartAssistant, {
  withSite: true,
  withConnect: true,
  withRouter: true
});
