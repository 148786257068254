import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HandleType from "@prop-types/HandleType";
import JSXProps from "@prop-types/JSXProps";
import { OffCanvasSidebarBS, LayoutFullWidthBS } from "@style-variables";
import { debug } from "@utils/debug";
import { isEnterKeyPressed } from "@utils/dom";
import { getComponentClassName, joinNonEmptyStrings } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { CloseButton, Container } from "react-bootstrap";
import PureComponent from "./PureComponent";

/**
 * @description Generic off-canvas sidebar component
 * @export
 * @class OffCanvasSidebar
 * @extends {PureComponent}
 */
export default class OffCanvasSidebar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { open: false };

    this.handleSidebarClose = this.handleSidebarClose.bind(this);
    this.handleHandlerToogle = this.handleHandlerToogle.bind(this);

    if (this.props.handler.title) {
      this.validateHandlerIcons();
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("noscroll");
  }

  /**
   * @description Handle the click event of sidebar close button
   * @param {Event} e
   * @memberof OffCanvasSidebar
   */
  handleSidebarClose(e) {
    this.setState({ open: false }, () =>
      document.body.classList.remove("noscroll")
    );
  }

  /**
   * @description Handle the toggling of sidebar handle
   * @param {Event} e
   * @memberof OffCanvasSidebar
   */
  handleHandlerToogle(e) {
    this.setState({ open: !this.state.open }, () =>
      document.body.classList.add("noscroll")
    );
  }

  /**
   * @description Validate the sidebar handler icons
   * @memberof OffCanvasSidebar
   */
  validateHandlerIcons() {
    if (!this.props.handler.title) {
      if (!this.props.handler.close) {
        debug(
          "The offCanvasSidebar `Close` handle icon is not defined",
          "warn"
        );
      }
      if (!this.props.handler.open) {
        debug("The offCanvasSidebar `Open` handle icon is not defined", "warn");
      }
    }
  }

  /**
   * @description Render the sidebar header
   * @returns {JSX|null}
   * @memberof OffCanvasSidebar
   */
  renderHeader() {
    if (!(this.props.hasCloseBtn || this.props.header)) {
      return null;
    }

    const closeBtn = this.props.hasCloseBtn ? (
      <CloseButton
        onClick={this.handleSidebarClose}
        onKeyPress={e => {
          if (isEnterKeyPressed(e)) {
            this.handleSidebarClose(e);
          }
        }}
      />
    ) : null;

    let header = { className: null, title: null };
    if (this.props.header) {
      header = { ...header, ...this.props.header };
    }

    return (
      <div
        className={getComponentClassName(
          OffCanvasSidebarBS,
          "header",
          joinNonEmptyStrings("sticky-top", header.className, " ")
        )}
      >
        <div
          className={joinNonEmptyStrings(
            "w-100 my-auto",
            header.className,
            " "
          )}
        >
          {header.title}
        </div>
        {closeBtn}
      </div>
    );
  }

  /**
   * @description Render the sidebar enclosing content
   * @returns {JSX|null}
   * @memberof OffCanvasSidebar
   */
  renderBody() {
    if (!this.props.children) {
      return null;
    }

    const headerHeight = 3.5;
    const footerHeight = 3.5;
    const fhHeight = headerHeight + (this.props.footer ? footerHeight : 0);

    return (
      <div
        className={getComponentClassName(OffCanvasSidebarBS, "body")}
        style={{ minHeight: "calc(100% - " + fhHeight + "rem)" }}
      >
        {this.props.children}
      </div>
    );
  }

  /**
   * @description Render the sidebar footer
   * @returns {JSX|null}
   * @memberof OffCanvasSidebar
   */
  renderFooter() {
    if (!this.props.footer) {
      return null;
    }

    return (
      <Container
        className={getComponentClassName(
          OffCanvasSidebarBS,
          "footer",
          "my-auto"
        )}
      >
        {this.props.footer}
      </Container>
    );
  }

  /**
   * @description Render the sidebar handler
   * @returns {JSX|null}
   * @memberof OffCanvasSidebar
   */
  renderHandler() {
    if (this.props.handler) {
      if (this.props.handler.type === HandleType.CUSTOM) {
        this.props.handler.onToggle(this.handleHandlerToogle);

        return null;
      }
    }

    let content = this.props.handler.title;

    if (!content) {
      const size = this.props.handler.size + "x";
      if (this.state.open) {
        if (this.props.handler.close) {
          content = (
            <FontAwesomeIcon icon={this.props.handler.close} size={size} />
          );
        }
      } else {
        if (this.props.handler.open) {
          content = (
            <FontAwesomeIcon icon={this.props.handler.open} size={size} />
          );
        }
      }
    }

    if (content) {
      let style = null;
      if (this.props.handler.title) {
        style = {
          width: "2rem",
          height: this.props.handler.title.length + "rem",
          top: "calc(50% - " + this.props.handler.title.length / 2 + "rem)"
        };
      }

      return (
        <div
          role="button"
          tabIndex={0}
          className={getComponentClassName(OffCanvasSidebarBS, "handler")}
          style={style}
          onClick={this.handleHandlerToogle}
          onKeyDown={e => {
            if (isEnterKeyPressed(e)) {
              this.handleHandlerToogle(e);
            }
          }}
        >
          <div
            className={getComponentClassName(
              OffCanvasSidebarBS,
              "handler-" + (this.props.handler.title ? "text" : "icon")
            )}
          >
            {content}
          </div>
        </div>
      );
    }

    return null;
  }

  render() {
    const cn = [
      "ignore-" + LayoutFullWidthBS,
      this.state.open ? "" : "collapsed"
    ]
      .filter(Boolean)
      .join(" ");

    const className = [this.props.className ? this.props.className : "", cn]
      .filter(Boolean)
      .join(" ");

    const wrapper = this.props.opacity ? (
      <div
        className={getComponentClassName(OffCanvasSidebarBS, "opacity", cn)}
      />
    ) : null;

    return (
      <React.Fragment>
        {wrapper}
        <div
          className={getComponentClassName(OffCanvasSidebarBS, null, className)}
        >
          <div className={getComponentClassName(OffCanvasSidebarBS, "inner")}>
            {this.renderHeader()}
            {this.renderBody()}
            {this.renderFooter()}
          </div>
          {this.renderHandler()}
        </div>
      </React.Fragment>
    );
  }
}

OffCanvasSidebar.propTypes = {
  hasCloseBtn: PropTypes.bool,
  handler: PropTypes.shape({
    title: PropTypes.string,
    size: PropTypes.number,
    open: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    close: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    type: PropTypes.oneOf([HandleType.SIDE, HandleType.CUSTOM]).isRequired,
    onToggle: PropTypes.func
  }),
  className: PropTypes.string,
  header: PropTypes.shape({
    title: PropTypes.string,
    className: PropTypes.string
  }),
  footer: JSXProps(),
  opacity: PropTypes.bool
};

OffCanvasSidebar.defaultProps = {
  hasCloseBtn: true,
  handler: {
    open: "chevron-right",
    close: "chevron-left",
    size: 2,
    type: HandleType.CUSTOM
  },
  opacity: true
};
